<template>
  <div class="px-2 home">
    <div class="container px-4 mx-auto mt-20 text-center hero">
      <h1 class="px-4 mb-6">Productivity Made Simple</h1>
      <p class="text-lg text-gray-600 mb-7 tracking-2xl lg:text-2xl">
        All-in-one app to manage your notes, tasks, and calendar with ease.<br>Optimize your workflow and focus on what matters most.
      </p>
      <p class="text-lg text-gray-500">Available in Web. Free while in Beta.</p>
      <router-link to="/register" class="px-5 py-2 mt-5 text-lg font-bold bg-red-600 lg:py-3 lg:text-2xl rounded-xl hover:bg-red-500 btn">Try Now →</router-link>
    </div>
    <div class="container relative max-w-6xl mx-auto mt-12 mb-16">
      <div class="absolute rounded-lg opacity-25 -inset-1 bg-gradient-to-r from-purple-600 to-red-600 filter blur-2xl"></div>
      <img src="/img/screenshot-1.png" class="relative leading-none bg-white border shadow overflow-clip rounded-xl lg:rounded-2xl lg:shadow-xl"/>
    </div>
    <div class="container mx-auto mt-40">
      <div class="max-w-xl mx-auto text-center">
        <h2>Everything starts with writing</h2>
        <p class="mb-8 text-xl text-gray-600">Start a project, take meeting notes, or write a blog.<br>Gather your ideas, and plan actions directly inside note. Markdown and keyboard shortcuts enabled.</p>
      </div>
      <div class="mx-2 lg:flex">
        <img src="/img/screenshot-2.png" width="900" class="mt-2 border border-gray-100 shadow rounded-xl lg:shadow-lg lg:m-4 lg:w-1/2"/>
        <img src="/img/screenshot-3.png" width="900" class="mt-2 border border-gray-100 shadow rounded-xl lg:shadow-lg lg:m-4 lg:w-1/2"/>
      </div>
    </div>
    <div class="container mx-auto mt-40">
      <div class="max-w-xl mx-auto text-center">
        <h2>See and manage all task</h2>
        <p class="mb-8 text-xl text-gray-600">Task you created including from the notes appears in one place. Prioritize, schedule and take actions.</p>
      </div>
      <div class="mx-2 lg:flex">
        <img src="/img/screenshot-4.png" width="900" class="mx-auto mt-2 border border-gray-100 shadow rounded-xl lg:shadow-lg lg:my-4 lg:w-1/2"/>
      </div>
    </div>
    <div class="container mx-auto mt-40">
      <div class="max-w-md mx-auto text-center">
        <h2>Manage your schedule</h2>
        <p class="mb-8 text-xl text-gray-600">Create events, schedule tasks and notes. Everything is card based drag-n-drop.</p>
      </div>
      <div class="mx-2 lg:flex">
        <img src="/img/screenshot-5.png" width="900" class="mt-2 border border-gray-100 shadow rounded-xl lg:shadow-lg lg:m-4 lg:w-1/2"/>
        <img src="/img/screenshot-6.png" width="900" class="mt-2 border border-gray-100 shadow rounded-xl lg:shadow-lg lg:m-4 lg:w-1/2"/>
      </div>
    </div>
    <div class="py-32 my-24 bg-gray-100">
      <div class="container max-w-md px-4 mx-auto lg:max-w-2xl">
        <p class="text-4xl font-normal leading-normal text-center lg:leading-snug lg:text-5xl lg:tracking-4xl">Built for your focus with <em>simplicity</em> in mind, no complex setup or learning needed.</p>
      </div>
    </div>
    <div class="container mx-auto">
      <h2 class="text-4xl text-center tracking-4xl">Pricing</h2>
      <div class="max-w-3xl mx-auto mt-12 md:flex">
        <div class="mx-4 mt-4 text-center border-2 border-purple-500 select-none py-7 rounded-xl lg:py-10 md:mt-0 md:w-1/3">
          <h3 class="mt-0 mb-1">Free Plan</h3>
          <p class="text-gray-500">Free while in <span class="font-semibold text-red-500">BETA</span></p>
          <p class="mt-6 mb-1 text-4xl font-bold lg:mt-10">$0</p>
        </div>
        <div class="mx-4 mt-4 text-center border-2 border-gray-200 select-none py-7 rounded-xl lg:py-10 md:mt-0 md:w-1/3">
          <h3 class="mt-0 mb-1">Monthly Plan</h3>
          <p class="text-gray-500">Billed monthly</p>
          <p class="mt-6 mb-1 text-4xl font-bold lg:mt-10">$4<span class="absolute text-xs text-gray-700">/mth</span></p>
        </div>
        <div class="mx-4 mt-4 text-center border-2 border-gray-200 select-none py-7 rounded-xl lg:py-10 md:mt-0 md:w-1/3">
          <h3 class="mt-0 mb-1">Annual Plan</h3>
          <p class="text-gray-500">$36 billed yearly</p>
          <p class="mt-6 mb-1 text-4xl font-bold lg:mt-10">$3<span class="absolute text-xs text-gray-700">/mth</span></p>
        </div>
      </div>
      <div class="flex justify-center mt-10">
        <router-link to="/register" class="px-5 py-2 mt-5 text-lg font-bold bg-red-600 lg:py-3 lg:text-2xl rounded-xl hover:bg-red-500 btn">Try Now →</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      email: ''
    }
  },
  mounted() {
    this.$ga.page('/')
  }
}
</script>

<style scoped>
h1 {
  @apply mt-4 mb-8 text-4xl font-extrabold leading-tight tracking-3xl lg:text-5xl lg:leading-tight lg:tracking-4xl;
}
h2 {
  @apply mt-4 mb-6 text-xl font-bold leading-tight tracking-xl lg:text-4xl lg:leading-tight lg:tracking-2xl;
}
</style>
